@tailwind base;
@tailwind components;
@tailwind utilities;

/* Waves */
.path-0 {
  animation:pathAnim-0 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0%{
    d: path("M 0,400 C 0,400 0,100 0,100 C 27.388461656464408,105.23599276239649 54.776923312928815,110.47198552479298 86,105 C 117.22307668707118,99.52801447520702 152.28076840474915,83.34805066322458 188,88 C 223.71923159525085,92.65194933677542 260.10000306807456,118.1358118223087 283,118 C 305.89999693192544,117.8641881776913 315.31921932295273,92.10870204754059 345,89 C 374.68078067704727,85.89129795245941 424.6231196401148,105.42937998752889 456,114 C 487.3768803598852,122.57062001247111 500.1883021165879,120.1737780023438 529,127 C 557.8116978834121,133.8262219976562 602.6236718935335,149.87550800309594 633,136 C 663.3763281064665,122.12449199690407 679.3170103092784,78.32418998527245 705,66 C 730.6829896907216,53.675810014727546 766.1082868693532,72.82773205581424 797,87 C 827.8917131306468,101.17226794418576 854.2498422133089,110.36488179147065 890,115 C 925.7501577866911,119.63511820852935 970.892344277411,119.7127407783032 1001,115 C 1031.107655722589,110.2872592216968 1046.1807806770473,100.78415509531655 1070,96 C 1093.8192193229527,91.21584490468345 1126.3845330144,91.15063884043062 1156,91 C 1185.6154669856,90.84936115956938 1212.2810872653538,90.61328954296098 1247,95 C 1281.7189127346462,99.38671045703902 1324.4911179241844,108.39620298772545 1358,110 C 1391.5088820758156,111.60379701227455 1415.7544410379078,105.80189850613728 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  25%{
    d: path("M 0,400 C 0,400 0,100 0,100 C 30.58074088208553,105.10742088672363 61.16148176417106,110.21484177344726 88,101 C 114.83851823582894,91.78515822655274 137.93481382540133,68.24805379293457 166,80 C 194.06518617459867,91.75194620706543 227.09926293422353,138.79294305481451 262,135 C 296.90073706577647,131.20705694518549 333.6681344377045,76.58017398780737 369,63 C 404.3318655622955,49.41982601219263 438.22819931495843,76.88636099395598 466,81 C 493.77180068504157,85.11363900604402 515.4190683024617,65.87438203636873 538,62 C 560.5809316975383,58.125617963631264 584.0955274751946,69.6161108605691 614,69 C 643.9044725248054,68.3838891394309 680.19882179676,55.66117452135492 713,70 C 745.80117820324,84.33882547864508 775.1091853377658,125.73919105401117 808,125 C 840.8908146622342,124.26080894598883 877.3644368521773,81.38206126260033 903,70 C 928.6355631478227,58.61793873739966 943.4330672535248,78.73256389558749 973,97 C 1002.5669327464752,115.26743610441251 1046.903294133724,131.68768315504974 1082,127 C 1117.096705866276,122.31231684495025 1142.953756211579,96.51670348421348 1172,90 C 1201.046243788421,83.48329651578652 1233.2816810199595,96.24550290809636 1264,110 C 1294.7183189800405,123.75449709190364 1323.919519708583,138.50128488340104 1353,137 C 1382.080480291417,135.49871511659896 1411.0402401457086,117.74935755829948 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  50%{
    d: path("M 0,400 C 0,400 0,100 0,100 C 35.666139098399334,111.3946428532295 71.33227819679867,122.789285706459 97,126 C 122.66772180320133,129.210714293541 138.33702631120468,124.23750002739352 169,110 C 199.66297368879532,95.76249997260648 245.31961655838268,72.26071418396691 275,66 C 304.6803834416173,59.739285816033096 318.3845074552646,70.71964323673883 346,74 C 373.6154925447354,77.28035676326117 415.1423536205589,72.86071286907782 453,81 C 490.8576463794411,89.13928713092218 525.0460780625,109.83750528694989 553,117 C 580.9539219375,124.16249471305011 602.6733341294412,117.7892659831226 627,109 C 651.3266658705588,100.2107340168774 678.260585419735,89.00543078055965 710,90 C 741.739414580265,90.99456921944035 778.2843241916191,104.18901089463878 812,112 C 845.7156758083809,119.81098910536122 876.6021178137887,122.23852564088514 904,122 C 931.3978821862113,121.76147435911486 955.307204553226,118.85688654182056 981,122 C 1006.692795446774,125.14311345817944 1034.1690639733067,134.33392819183263 1065,124 C 1095.8309360266933,113.66607180816739 1130.0165395535466,83.80740069084901 1162,76 C 1193.9834604464534,68.19259930915099 1223.7647778125067,82.43646904477141 1251,90 C 1278.2352221874933,97.56353095522859 1302.9243491964266,98.44672313006531 1334,99 C 1365.0756508035734,99.55327686993469 1402.5378254017867,99.77663843496734 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  75%{
    d: path("M 0,400 C 0,400 0,100 0,100 C 25.285098097770295,98.39621603389064 50.57019619554059,96.79243206778128 81,86 C 111.42980380445941,75.20756793221872 147.00431331560793,55.22648776276553 179,70 C 210.99568668439207,84.77351223723447 239.41255054202782,134.30161688115666 267,133 C 294.5874494579722,131.69838311884334 321.3454845162809,79.5670447126079 350,70 C 378.6545154837191,60.43295528739211 409.2055113928487,93.43020426841176 438,97 C 466.7944886071513,100.56979573158824 493.8324699123243,74.71213821374508 528,65 C 562.1675300876757,55.28786178625492 603.464608957854,61.7212428766079 633,66 C 662.535391042146,70.2787571233921 680.3090942562592,72.40289027982327 712,72 C 743.6909057437408,71.59710972017673 789.2990140171091,68.66719600409901 818,81 C 846.7009859828909,93.33280399590099 858.4948496753044,120.92832570378069 891,121 C 923.5051503246956,121.07167429621931 976.7215872816728,93.61950118077824 1006,89 C 1035.2784127183272,84.38049881922176 1040.6188011980048,102.59366957310638 1064,106 C 1087.3811988019952,109.40633042689362 1128.803207926308,98.00582052679624 1167,86 C 1205.196792073692,73.99417947320376 1240.1683670967632,61.383048319708706 1273,70 C 1305.8316329032368,78.6169516802913 1336.523323686639,108.46198619436895 1364,117 C 1391.476676313361,125.53801380563105 1415.7383381566806,112.76900690281553 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  100%{
    d: path("M 0,400 C 0,400 0,100 0,100 C 27.388461656464408,105.23599276239649 54.776923312928815,110.47198552479298 86,105 C 117.22307668707118,99.52801447520702 152.28076840474915,83.34805066322458 188,88 C 223.71923159525085,92.65194933677542 260.10000306807456,118.1358118223087 283,118 C 305.89999693192544,117.8641881776913 315.31921932295273,92.10870204754059 345,89 C 374.68078067704727,85.89129795245941 424.6231196401148,105.42937998752889 456,114 C 487.3768803598852,122.57062001247111 500.1883021165879,120.1737780023438 529,127 C 557.8116978834121,133.8262219976562 602.6236718935335,149.87550800309594 633,136 C 663.3763281064665,122.12449199690407 679.3170103092784,78.32418998527245 705,66 C 730.6829896907216,53.675810014727546 766.1082868693532,72.82773205581424 797,87 C 827.8917131306468,101.17226794418576 854.2498422133089,110.36488179147065 890,115 C 925.7501577866911,119.63511820852935 970.892344277411,119.7127407783032 1001,115 C 1031.107655722589,110.2872592216968 1046.1807806770473,100.78415509531655 1070,96 C 1093.8192193229527,91.21584490468345 1126.3845330144,91.15063884043062 1156,91 C 1185.6154669856,90.84936115956938 1212.2810872653538,90.61328954296098 1247,95 C 1281.7189127346462,99.38671045703902 1324.4911179241844,108.39620298772545 1358,110 C 1391.5088820758156,111.60379701227455 1415.7544410379078,105.80189850613728 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
}

.path-1 {
  animation:pathAnim-1 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0%{
    d: path("M 0,400 C 0,400 0,200 0,200 C 36.7113804991225,179.0017894671983 73.422760998245,158.00357893439664 101,171 C 128.577239001755,183.99642106560336 147.02033650614248,230.98747372961174 172,234 C 196.97966349385752,237.01252627038826 228.49589297718518,196.04652614715656 257,177 C 285.5041070228148,157.95347385284344 310.9960915851168,160.82642168176193 343,174 C 375.0039084148832,187.17357831823807 413.5197406823476,210.6477871257957 444,223 C 474.4802593176524,235.3522128742043 496.92494568549273,236.58242981505538 528,225 C 559.0750543145073,213.41757018494462 598.7804765756814,189.02249361398285 633,184 C 667.2195234243186,178.97750638601715 695.9531480117819,193.32759572901327 723,200 C 750.0468519882181,206.67240427098673 775.4069313771905,205.66712346996417 807,201 C 838.5930686228095,196.33287653003583 876.4191264794558,188.00391039113018 909,179 C 941.5808735205442,169.99608960886982 968.9165627049866,160.3172349655151 994,164 C 1019.0834372950134,167.6827650344849 1041.9146227005976,184.7271497468095 1071,186 C 1100.0853772994024,187.2728502531905 1135.4249464926233,172.77416604724695 1169,170 C 1202.5750535073767,167.22583395275305 1234.3855913289087,176.17618606420277 1267,175 C 1299.6144086710913,173.82381393579723 1333.0326881917404,162.52108969594207 1362,165 C 1390.9673118082596,167.47891030405793 1415.4836559041298,183.73945515202897 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  25%{
    d: path("M 0,400 C 0,400 0,200 0,200 C 35.20270216752423,207.38027791981102 70.40540433504846,214.76055583962201 99,212 C 127.59459566495154,209.23944416037799 149.5810848273304,196.33805456132288 181,188 C 212.4189151726696,179.66194543867712 253.27025635563,175.88722591508647 283,172 C 312.72974364437,168.11277408491353 331.3378897501499,164.11304177833117 360,169 C 388.6621102498501,173.88695822166883 427.3781846437704,187.66060697158892 455,187 C 482.6218153562296,186.33939302841108 499.14937167476864,171.24453033531316 531,180 C 562.8506283252314,188.75546966468684 610.0243286571547,221.3612716871585 644,236 C 677.9756713428453,250.6387283128415 698.7533136966127,247.310382916053 728,226 C 757.2466863033873,204.689617083947 794.9624165563947,165.39719664862946 822,169 C 849.0375834436053,172.60280335137054 865.3970200778085,219.1008304894291 894,229 C 922.6029799221915,238.8991695105709 963.4495031323711,212.19948139365408 995,204 C 1026.550496867629,195.80051860634592 1048.804967392707,206.10124393595456 1074,217 C 1099.195032607293,227.89875606404544 1127.3306272968002,239.39554286252766 1161,223 C 1194.6693727031998,206.60445713747234 1233.872523420092,162.31658461393488 1265,164 C 1296.127476579908,165.68341538606512 1319.1792790228308,213.3381186817329 1347,227 C 1374.8207209771692,240.6618813182671 1407.4103604885845,220.33094065913355 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  50%{
    d: path("M 0,400 C 0,400 0,200 0,200 C 21.976457693482608,195.44209285183243 43.952915386965216,190.88418570366485 78,184 C 112.04708461303478,177.11581429633515 158.16479614562175,167.905350037173 195,165 C 231.83520385437825,162.094649962827 259.3879000305477,165.4944141476431 283,171 C 306.6120999694523,176.5055858523569 326.2836037321875,184.11699337225457 357,202 C 387.7163962678125,219.88300662774543 429.4776850407025,248.0376123633386 465,236 C 500.5223149592975,223.9623876366614 529.8056561050026,171.732557174391 554,168 C 578.1943438949974,164.267442825609 597.299690539287,209.0321589390974 625,224 C 652.700309460713,238.9678410609026 688.9955817378496,224.1388070692194 720,216 C 751.0044182621504,207.8611929307806 776.717982509314,206.4126127840249 808,196 C 839.282017490686,185.5873872159751 876.1324882248941,166.21074179468116 901,173 C 925.8675117751059,179.78925820531884 938.752064591109,212.74442003725048 974,222 C 1009.247935408891,231.25557996274952 1066.8592534106697,216.81157805631688 1096,206 C 1125.1407465893303,195.18842194368312 1125.8109217662125,188.00926773748208 1154,187 C 1182.1890782337875,185.99073226251792 1237.897059524481,191.15135099375482 1274,187 C 1310.102940475519,182.84864900624518 1326.6008401358627,169.38532828749862 1351,170 C 1375.3991598641373,170.61467171250138 1407.6995799320687,185.3073358562507 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  75%{
    d: path("M 0,400 C 0,400 0,200 0,200 C 31.39420565260646,212.45219858652553 62.78841130521292,224.90439717305108 95,223 C 127.21158869478708,221.09560282694892 160.24056043175477,204.8346098943213 191,204 C 221.75943956824523,203.1653901056787 250.24934696776796,217.75716324966382 282,212 C 313.75065303223204,206.24283675033618 348.76205169717355,180.13673710702332 375,180 C 401.23794830282645,179.86326289297668 418.7024462435379,205.69588832224298 447,214 C 475.2975537564621,222.30411167775702 514.4281633286746,213.0797096040047 549,204 C 583.5718366713254,194.9202903959953 613.5849004417636,185.98527326173817 636,187 C 658.4150995582364,188.01472673826183 673.232234904271,198.97919734904272 706,210 C 738.767765095729,221.02080265095728 789.4861599411524,232.09793734209103 818,224 C 846.5138400588476,215.90206265790897 852.8231253311192,188.6290532825932 885,190 C 917.1768746688808,191.3709467174068 975.2213387343706,221.38584952753612 1006,228 C 1036.7786612656294,234.61415047246388 1040.2915197313978,217.8275486072624 1069,213 C 1097.7084802686022,208.1724513927376 1151.6125823400378,215.3039560434142 1185,224 C 1218.3874176599622,232.6960439565858 1231.2581509084514,242.9566272190808 1257,233 C 1282.7418490915486,223.0433727809192 1321.3548140261566,192.86953508026264 1354,184 C 1386.6451859738434,175.13046491973736 1413.3225929869218,187.5652324598687 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  100%{
    d: path("M 0,400 C 0,400 0,200 0,200 C 36.7113804991225,179.0017894671983 73.422760998245,158.00357893439664 101,171 C 128.577239001755,183.99642106560336 147.02033650614248,230.98747372961174 172,234 C 196.97966349385752,237.01252627038826 228.49589297718518,196.04652614715656 257,177 C 285.5041070228148,157.95347385284344 310.9960915851168,160.82642168176193 343,174 C 375.0039084148832,187.17357831823807 413.5197406823476,210.6477871257957 444,223 C 474.4802593176524,235.3522128742043 496.92494568549273,236.58242981505538 528,225 C 559.0750543145073,213.41757018494462 598.7804765756814,189.02249361398285 633,184 C 667.2195234243186,178.97750638601715 695.9531480117819,193.32759572901327 723,200 C 750.0468519882181,206.67240427098673 775.4069313771905,205.66712346996417 807,201 C 838.5930686228095,196.33287653003583 876.4191264794558,188.00391039113018 909,179 C 941.5808735205442,169.99608960886982 968.9165627049866,160.3172349655151 994,164 C 1019.0834372950134,167.6827650344849 1041.9146227005976,184.7271497468095 1071,186 C 1100.0853772994024,187.2728502531905 1135.4249464926233,172.77416604724695 1169,170 C 1202.5750535073767,167.22583395275305 1234.3855913289087,176.17618606420277 1267,175 C 1299.6144086710913,173.82381393579723 1333.0326881917404,162.52108969594207 1362,165 C 1390.9673118082596,167.47891030405793 1415.4836559041298,183.73945515202897 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
}

.path-2 {
  animation:pathAnim-2 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-2 {
  0%{
    d: path("M 0,400 C 0,400 0,300 0,300 C 22.144277115458927,323.87513732959957 44.28855423091785,347.75027465919914 80,336 C 115.71144576908215,324.24972534080086 164.9900601917875,276.87403869280297 196,272 C 227.0099398082125,267.12596130719703 239.75120500193202,304.7535705695888 266,323 C 292.248794998068,341.2464294304112 332.00511980048435,340.11167902884176 364,326 C 395.99488019951565,311.88832097115824 420.22831579613046,284.79971331504436 450,284 C 479.77168420386954,283.20028668495564 515.0816170149936,308.6894677109808 543,322 C 570.9183829850064,335.3105322890192 591.4452161438951,336.44241584103247 622,318 C 652.5547838561049,299.55758415896753 693.1375184094258,261.54086892488954 723,267 C 752.8624815905742,272.45913107511046 772.0047102184022,321.3941084594095 802,335 C 831.9952897815978,348.6058915405905 872.8436407169652,326.88269723747277 903,312 C 933.1563592830348,297.11730276252723 952.6207269137368,289.0751025906994 985,286 C 1017.3792730862632,282.9248974093006 1062.6734516280872,284.81689239972974 1094,286 C 1125.3265483719128,287.18310760027026 1142.685466573915,287.6573278103818 1168,282 C 1193.314533426085,276.3426721896182 1226.5846820762538,264.55379635874334 1254,266 C 1281.4153179237462,267.44620364125666 1302.9758051210704,282.12748675464474 1333,290 C 1363.0241948789296,297.87251324535526 1401.512097439465,298.93625662267766 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  25%{
    d: path("M 0,400 C 0,400 0,300 0,300 C 18.62302393399476,285.86942001279203 37.24604786798952,271.738840025584 73,266 C 108.75395213201048,260.261159974416 161.6388324620367,262.91405991045605 194,279 C 226.3611675379633,295.08594008954395 238.1986222838637,324.6049203325918 268,324 C 297.8013777161363,323.3950796674082 345.5666784025084,292.66625875917674 375,287 C 404.4333215974916,281.33374124082326 415.5346641061027,300.73004463070123 440,302 C 464.4653358938973,303.26995536929877 502.2946651730806,286.41356271801817 533,290 C 563.7053348269194,293.58643728198183 587.2866752015751,317.615704497226 616,327 C 644.7133247984249,336.384295502774 678.5586340206186,331.12361929307804 716,318 C 753.4413659793814,304.87638070692196 794.4787887159506,283.8898183304618 825,278 C 855.5212112840494,272.1101816695382 875.5262111155793,281.3171073850746 903,289 C 930.4737888844207,296.6828926149254 965.4163668217325,302.8417521292398 999,307 C 1032.5836331782675,311.1582478707602 1064.8083215974914,313.31588409796615 1091,303 C 1117.1916784025086,292.68411590203385 1137.3503467883015,269.8947114788957 1164,276 C 1190.6496532116985,282.1052885211043 1223.7902912493032,317.10526998645116 1256,319 C 1288.2097087506968,320.89473001354884 1319.4884882144847,289.6842085752997 1350,281 C 1380.5115117855153,272.3157914247003 1410.2557558927576,286.1578957123502 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  50%{
    d: path("M 0,400 C 0,400 0,300 0,300 C 38.66206704145962,315.2993797842298 77.32413408291924,330.5987595684597 102,335 C 126.67586591708076,339.4012404315403 137.36553070978263,332.9043415103912 165,322 C 192.63446929021737,311.0956584896088 237.21374307795026,295.7838743899756 277,291 C 316.78625692204974,286.2161256100244 351.77949697841626,291.9601609297065 375,294 C 398.22050302158374,296.0398390702935 409.668269008385,294.3754818911987 435,296 C 460.331730991615,297.6245181088013 499.54742698804375,302.5379115054987 536,303 C 572.4525730119562,303.4620884945013 606.1420230394399,299.4728720868065 637,288 C 667.8579769605601,276.5271279131935 695.8844808541972,257.5706001472754 721,272 C 746.1155191458028,286.4293998527246 768.320053543771,334.2447273240919 797,332 C 825.679946456229,329.7552726759081 860.835304970719,277.45049055635695 897,273 C 933.164695029281,268.54950944364305 970.3387265733527,311.9533104504802 999,322 C 1027.6612734266473,332.0466895495198 1047.8097887358697,308.7362676417222 1073,294 C 1098.1902112641303,279.2637323582778 1128.4221184831688,273.1016189826312 1159,276 C 1189.5778815168312,278.8983810173688 1220.5017373314547,290.85725642775316 1251,292 C 1281.4982626685453,293.14274357224684 1311.570932191013,283.46935530635625 1343,283 C 1374.429067808987,282.53064469364375 1407.2145339044935,291.2653223468219 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  75%{
    d: path("M 0,400 C 0,400 0,300 0,300 C 33.77862371693631,289.48730705470086 67.55724743387262,278.9746141094018 98,287 C 128.44275256612738,295.0253858905982 155.5496339814458,321.5888506170939 186,331 C 216.4503660185542,340.4111493829061 250.24421664034406,332.66998342222274 280,312 C 309.75578335965594,291.33001657777726 335.47349945717787,257.7312156940153 360,263 C 384.52650054282213,268.2687843059847 407.86178553094476,312.4051538017161 444,329 C 480.13821446905524,345.5948461982839 529.079358419043,334.6481690991202 555,319 C 580.920641580957,303.3518309008798 583.8207807928828,283.0021698018032 614,273 C 644.1792192071172,262.9978301981968 701.6375184094256,263.34315169366715 735,279 C 768.3624815905744,294.65684830633285 777.6291455694146,325.62522342352815 802,324 C 826.3708544305854,322.37477657647185 865.8458993129155,288.15595461222 900,281 C 934.1541006870845,273.84404538778 962.9872571789226,293.7509581275917 988,309 C 1013.0127428210774,324.2490418724083 1034.2050719713936,334.84021287741325 1066,334 C 1097.7949280286064,333.15978712258675 1140.1924549355026,320.8881903627552 1173,308 C 1205.8075450644974,295.1118096372448 1229.0251082865952,281.6070256715659 1253,283 C 1276.9748917134048,284.3929743284341 1301.7071119181157,300.68370695098116 1333,306 C 1364.2928880818843,311.31629304901884 1402.1464440409422,305.65814652450945 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  100%{
    d: path("M 0,400 C 0,400 0,300 0,300 C 22.144277115458927,323.87513732959957 44.28855423091785,347.75027465919914 80,336 C 115.71144576908215,324.24972534080086 164.9900601917875,276.87403869280297 196,272 C 227.0099398082125,267.12596130719703 239.75120500193202,304.7535705695888 266,323 C 292.248794998068,341.2464294304112 332.00511980048435,340.11167902884176 364,326 C 395.99488019951565,311.88832097115824 420.22831579613046,284.79971331504436 450,284 C 479.77168420386954,283.20028668495564 515.0816170149936,308.6894677109808 543,322 C 570.9183829850064,335.3105322890192 591.4452161438951,336.44241584103247 622,318 C 652.5547838561049,299.55758415896753 693.1375184094258,261.54086892488954 723,267 C 752.8624815905742,272.45913107511046 772.0047102184022,321.3941084594095 802,335 C 831.9952897815978,348.6058915405905 872.8436407169652,326.88269723747277 903,312 C 933.1563592830348,297.11730276252723 952.6207269137368,289.0751025906994 985,286 C 1017.3792730862632,282.9248974093006 1062.6734516280872,284.81689239972974 1094,286 C 1125.3265483719128,287.18310760027026 1142.685466573915,287.6573278103818 1168,282 C 1193.314533426085,276.3426721896182 1226.5846820762538,264.55379635874334 1254,266 C 1281.4153179237462,267.44620364125666 1302.9758051210704,282.12748675464474 1333,290 C 1363.0241948789296,297.87251324535526 1401.512097439465,298.93625662267766 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
}


/* Background animation */
.background-animate {
  background-size: 400%;

  -webkit-animation: BackgroundGradient 8s ease infinite;
  -moz-animation: BackgroundGradient 8s ease infinite;
  animation: BackgroundGradient 8s ease infinite;
}

@keyframes BackgroundGradient {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.scrollbar-invisible::-webkit-scrollbar {
  display: none;
}

.scrollbar-invisible {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollable-line-chart {
  width: 1000px;
}

g text {
  font-family: GothamBook, sans-serif !important;
}

.gotham-bold {
  font-family: GothamBold, sans-serif !important;
}

.date-picker-wrapper input {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px
}

.bg-blur {background-color: rgba(234, 237, 240, 0); backdrop-filter: blur(18px);}